import React from "react"
import { useTranslation } from "react-i18next"

const Footer = () => {
  const year = new Date().getFullYear()
  const [t] = useTranslation()

  return (
    <footer className="app-footer">
      <div className="container">
        <div className="disclaimer">
          &copy; {year} Hyperian. {t("common.PAGE_FOOTER")}
        </div>
      </div>
    </footer>
  )
}

export default Footer
