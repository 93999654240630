import React from "react"
import { BrowserRouter } from "react-router-dom"
import Routes from "Routes"
import "react-big-calendar/lib/sass/styles.scss"
import "react-toggle-switch/dist/css/switch.min.css"
import "rc-drawer/assets/index.css"
import "styles/app.scss"
import moment from "moment/min/moment-with-locales"
import localeStore from "./stores/LocaleStore"
import Highcharts from "highcharts"
import { withTranslation } from "react-i18next"

class App extends React.Component {
  localizeHighcharts() {
    const { t } = this.props

    Highcharts.setOptions({
      lang: {
        months: moment.months(),
        weekdays: moment.weekdays(),
        shortMonths: moment.monthsShort(),
        loading: t("common.LOADING") + "...",
        rangeSelectorFrom: t("common.FROM"),
        rangeSelectorTo: t("common.TO"),
        rangeSelectorZoom: t("common.DATES_RANGE"),
        resetZoom: t("common.RESET_ZOOM"),
        resetZoomTitle: t("common.RESET_ZOOM"),
      },
    })
  }

  componentDidMount() {
    const { current: currentLocale } = localeStore
    moment.locale(currentLocale || "en")

    this.localizeHighcharts()
  }

  render() {
    return (
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    )
  }
}

export default withTranslation()(App)
