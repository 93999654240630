import i18n from "i18next"
import Backend from "i18next-http-backend"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ["cookie", "localStorage", "sessionStorage"],
    },
    caches: ["cookie"],
    cookieOptions: { path: "/", sameSite: "strict" },
    fallbackLng: null,
    ns: ["main", "countries" /*, 'validation-errors'*/],
    defaultNS: "main",
    debug: true,
    react: {
      wait: true,
      useSuspense: true,
    },
  })

export default i18n
