import { computed, observable, toJS } from 'mobx';
import profileStore from "./ProfileStore";
import activeDeviceStore from "./ActiveDeviceStore";



class DeviceListStore {
  @observable deviceList = [];
  @observable loaded = false;
  @observable fetchingData = false;
  timerId = setInterval(() => {
    this.reload();
  }, 600000);

  set list(list) {
    this.loaded = true;
    this.deviceList = list;
  }

  @computed get list() {
    if (!this.loaded) {
      return this.fetch().then(result => this.list = result);
    }
    return this.deviceList;
  }

  @computed get count() {
    return this.list.length;
  }

  fetch = async() => {
    this.fetchingData = true;

    const user = await profileStore.user;

    this.fetchingData = false;

    return this.mapCollection(user.plants);
  }

  mapCollection = list => {
    return list.map(device => {
      return this.map(device);
    })
  }

  map = data => {


    return {
      id: data.plant_id,
      isOnline: data.is_online,
      backupEnabled: data.backup_enabled,
      soc: data.soc,
      applicationMode: {
        id: data.application_mode_id,
        value: data.application_mode
      },
      state: {
        id: data.state_id,
        value: data.state
      },
      numberOfBatteries: data.number_of_batteries,
      pcCode: data.pc_code,
      serialNumber: data.serial_number,
      extendedConsumptionData: data.extendedConsumptionData!=null ? data.extendedConsumptionData : 0,
      label: data.label!=null ? data.label: data.serial_number,

      firmwareVersion: data.firmware_version,
      power: data.power,
      lastHeartbeatAt: data.last_heartbeat_at,
      healthStatus: {
        id: data.health_status_id,
        value: data.health_status
      },
      hasWeather: data.has_weather,
      productFamily: data.product_family,
      codeName: data.code_name,
      vendor: data.vendor,
      weather: data.weather,
    }
  }

  reload = async() => {
    await profileStore.reload();
    this.list = await this.fetch();

    const active = await activeDeviceStore.device;
    if (active !== null) {
      const device = await this.device(active.id);
      if (null !== device) {
        activeDeviceStore.device = device;
      } else {
        activeDeviceStore.info = null;
        activeDeviceStore.deviceId = null;
      }
    }
  }

  device = async id => {
    const list = await this.list;
    const device = list.find(e => e.id === parseInt(id));

    return undefined === device ? null : device;
  }
}

const deviceListStore = new DeviceListStore();

export default deviceListStore;


