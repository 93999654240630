import http from "./http"

const Faq = {
  list: (locale = null) =>
    http.get(`/v1/faq/get-all-faq?locale=${locale}`).then((response) => {
      return response
    }),
}

export default Faq
