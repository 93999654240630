import React from "react"
import { Link } from "react-router-dom"
import classnames from "classnames"
import { withTranslation } from "react-i18next"
import { inject, observer } from "mobx-react"
import { Button, Input } from "reactstrap"
import PropTypes from "prop-types"
import ListErrors from "../../components/ListErrors"
import FormValidator from "../../components/Forms/Validator.js"
import IntlTelInput from "../../components/IntlTelInput"
import countryStore from "../../stores/CountryStore"

@inject("authStore")
@observer
class Signup extends React.Component {
  state = {
    formRegister: {
      passwordConfirm: "",
      agree: true,
    },
    errors: {},
    modal: false,
  }

  componentDidMount() {
    const { socialUser } = this.props.authStore
    if (socialUser) {
      this.props.authStore.setEmail(socialUser.email)
      this.props.authStore.setFirstname(socialUser.first_name)
      this.props.authStore.setLastname(socialUser.last_name)
      this.props.authStore.setPhone(socialUser.phone || "")
      this.props.authStore.setCountry(socialUser.country || "")
    }

    this.addValidationForPhone()
  }

  componentWillUnmount() {
    this.props.authStore.reset()
    this.props.authStore.socialUser = null
  }

  addValidationForPhone = () => {
    const phoneInput = document.querySelector('form[name="formRegister"] input[name="phone"]')
    phoneInput.dataset.validate = '["required","phone"]'
  }

  handleUsernameChange = (e) => {
    this.validateOnChange(e)
    this.props.authStore.setUsername(e.target.value)
  }

  handleEmailChange = (e) => {
    this.validateOnChange(e)
    this.props.authStore.setEmail(e.target.value)
  }

  handlePasswordChange = (e) => {
    this.validateOnChange(e)
    this.props.authStore.setPassword(e.target.value)
  }

  handlePasswordConfirmChange = (e) => {
    this.validateOnChange(e)
  }

  handleLastnameChange = (e) => {
    this.validateOnChange(e)
    this.props.authStore.setLastname(e.target.value)
  }

  validatePhone = (status, value) => {
    const phoneInput = document.querySelector('form[name="formRegister"] input[name="phone"]')
    const result = FormValidator.validate(phoneInput)

    this.setState({
      formRegister: {
        ...this.state.formRegister,
        phone: value,
      },
      errors: {
        ...this.state.formRegister.errors,
        phone: result,
      },
    })
  }

  handlePhoneChange = (status, value) => {
    this.props.authStore.setPhone(value)
  }

  handleCountryChange = (e) => {
    this.validateOnChange(e)
    this.props.authStore.setCountry(e.target.value)
  }

  handleFirstnameChange = (e) => {
    this.validateOnChange(e)
    this.props.authStore.setFirstname(e.target.value)
  }

  handleAgreeChange = (e) => {
    this.validateOnChange(e)
  }

  validateOnChange = (event) => {
    const input = event.target
    const form = input.form
    const value = input.type === "checkbox" ? input.checked : input.value

    const result = FormValidator.validate(input)

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
      },
      errors: {
        ...this.state[form.name].errors,
        [input.name]: result,
      },
    })
  }

  onSubmit = (e) => {
    const form = e.target
    const inputs = [...form.elements].filter((i) => ["INPUT", "SELECT"].includes(i.nodeName))

    const { errors, hasError } = FormValidator.bulkValidate(inputs)

    this.setState({
      errors,
    })

    e.preventDefault()

    if (!hasError) {
      this.props.authStore
        .register()
        .then(() => {
          this.props.history.push("/")
          //this.props.notificationStore.success('Congratulations. You are registered in the system. A message has been sent to your email.');
        })
        .catch((err) => {
          const errors = err.response && err.response.data && err.response.data.arguments
          if (errors && Object.keys(errors).length) {
            this.setState({
              errors,
            })
          }
        })
    }
  }

  hasError = (inputName) => {
    return this.state.errors && this.state.errors[inputName] && this.state.errors[inputName].length > 0
  }

  getErrors = (inputName) => {
    if (!this.hasError(inputName)) {
      return ""
    }
    return this.state.errors[inputName].join(", ")
  }

  toggleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }))
  }

  render() {
    const { t } = this.props
    const { values, errors, inProgress, socialUser } = this.props.authStore
    FormValidator.setTranslation(t)

    return (
      <div className="app-signup-container">
        <div className="app-signup-title">{t("userProfile.CREATE_ACCOUNT")}</div>

        <div className="row">
          <div className="col-12 col-md-6 offset-md-3">
            <ListErrors errors={errors} />

            <form action="post" name="formRegister" className="app-login-form" onSubmit={this.onSubmit}>
              {null === socialUser && (
                <div className="form-group">
                  <Input
                    type="text"
                    name="username"
                    placeholder={t("userProfile.USERNAME")}
                    invalid={this.hasError("username")}
                    onChange={this.handleUsernameChange}
                    data-validate='["required", "alphanum"]'
                    value={values.username}
                    autoComplete="off"
                  />
                  {this.hasError("username") && <span className="invalid-feedback"> {this.getErrors("username")}</span>}
                </div>
              )}

              <div className="form-group">
                <Input
                  type="text"
                  name="email"
                  placeholder={t("userProfile.EMAIL")}
                  invalid={this.hasError("email")}
                  readOnly={socialUser !== null && typeof socialUser.email === "string" && socialUser.email.search("@") > 0}
                  onChange={this.handleEmailChange}
                  data-validate='["required", "email"]'
                  value={values.email}
                  autoComplete="off"
                />
                {this.hasError("email") && <span className="invalid-feedback"> {this.getErrors("email")}</span>}
              </div>

              {null === socialUser && (
                <>
                  <div className="form-group">
                    <Input
                      type="password"
                      name="password"
                      id="formregister-password"
                      placeholder={t("userProfile.PASSWORD")}
                      invalid={this.hasError("password")}
                      onChange={this.handlePasswordChange}
                      data-validate='["required"]'
                      value={values.password}
                      autoComplete="off"
                    />
                    {this.hasError("password") && <span className="invalid-feedback"> {this.getErrors("password")}</span>}
                  </div>

                  <div className="form-group">
                    <Input
                      type="password"
                      name="passwordConfirm"
                      placeholder={t("userProfile.CONFIRM_PASSWORD")}
                      invalid={this.hasError("passwordConfirm")}
                      onChange={this.handlePasswordConfirmChange}
                      data-validate='["equalto"]'
                      value={this.state.formRegister.passwordConfirm}
                      data-param="formregister-password"
                      autoComplete="off"
                    />
                    {this.hasError("passwordConfirm") && <span className="invalid-feedback">{t("userProfile.PASSWORDS_ARE_NOT_EQUAL")}</span>}
                  </div>
                </>
              )}

              <div className="form-group">
                <Input
                  type="text"
                  name="firstname"
                  placeholder={t("userProfile.FIRST_NAME")}
                  invalid={this.hasError("firstname") || this.hasError("first_name")}
                  onChange={this.handleFirstnameChange}
                  data-validate='["required"]'
                  value={values.firstname}
                  autoComplete="off"
                />
                {this.hasError("firstname") && <span className="invalid-feedback"> {this.getErrors("firstname")}</span>}
                {this.hasError("first_name") && <span className="invalid-feedback"> {this.getErrors("first_name")}</span>}
              </div>

              <div className="form-group">
                <Input
                  type="text"
                  name="lastname"
                  placeholder={t("userProfile.LAST_NAME")}
                  invalid={this.hasError("lastname") || this.hasError("last_name")}
                  onChange={this.handleLastnameChange}
                  data-validate='["required"]'
                  value={values.lastname}
                  autoComplete="off"
                />
                {this.hasError("lastname") && <span className="invalid-feedback"> {this.getErrors("lastname")}</span>}
                {this.hasError("last_name") && <span className="invalid-feedback"> {this.getErrors("last_name")}</span>}
              </div>

              <div className="form-group">
                <select
                  name="country"
                  className={classnames("form-control py-0 text-center", {
                    "text-placeholder": values.country === "",
                    "is-invalid": this.hasError("country"),
                  })}
                  onChange={this.handleCountryChange}
                  data-validate='["required"]'
                >
                  <option disabled={true} selected={values.country === ""} value={""}>
                    {t("userProfile.COUNTRY")}
                  </option>
                  {Object.entries(countryStore.list()).map((row) => {
                    let [code, title] = row
                    return (
                      <option value={code} selected={values.country === code}>
                        {t(title, { ns: "countries" })}
                      </option>
                    )
                  })}
                </select>
                {this.hasError("country") && <span className="invalid-feedback d-block">{this.getErrors("country")}</span>}
              </div>

              <div className="form-group">
                <IntlTelInput
                  defailtPlaceholder={t("userProfile.PHONE")}
                  defaultCountry={values.country ? values.country : "au"}
                  preferredCountries={Object.keys(countryStore.list())}
                  fieldName="phone"
                  invalid={this.hasError("phone")}
                  onPhoneNumberChange={this.handlePhoneChange}
                  onPhoneNumberBlur={(status, value) => this.validatePhone(status, value)}
                  value={values.phone}
                  autoComplete="off"
                  inputClassName={classnames("form-control py-0 text-center", {
                    "text-placeholder": values.phone === "",
                    "is-invalid": this.hasError("phone"),
                  })}
                />
                {this.hasError("phone") && <span className="invalid-feedback d-block">{this.getErrors("phone")}</span>}
              </div>

              <div className="form-group">
                <div className="custom-control custom-checkbox my-3 mr-sm-2">
                  <Input
                    type="checkbox"
                    id="agree"
                    name="agree"
                    onChange={this.handleAgreeChange}
                    checked={this.state.formRegister.agree}
                    invalid={this.hasError("agree", "required")}
                    className="custom-control-input"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="agree"
                    dangerouslySetInnerHTML={{
                      __html: t("userProfile.AGREE_SIGNUP", {
                        interpolation: { escapeValue: false },
                      }),
                    }}
                  />
                </div>
              </div>

              <Button type="submit" color="primary" className="text-uppercase btn-block px-3" disabled={inProgress || !this.state.formRegister.agree}>
                {t("userProfile.SIGN_UP")}
              </Button>
            </form>

            <Link to="/signin" className="link app-signin-link">
              {t("userProfile.I_AM_ALREADY_A_MEMBER")}
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

Signup.contextTypes = {
  router: PropTypes.object,
}

export default withTranslation()(Signup)
