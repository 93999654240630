import React, { Component } from "react"
import { DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"
import sweetalert from "sweetalert"
import activeDeviceStore from "../../stores/ActiveDeviceStore"
import Icon from "../Icons"
import api from "../../api"
import moment from "moment/min/moment-with-locales"
import DateRangePicker from "react-bootstrap-daterangepicker"
import "bootstrap-daterangepicker/daterangepicker.css"
import swal from "sweetalert"
import { withTranslation } from "react-i18next"

const DATE_FORMAT = "DD-MM-YYYY"
const SERVER_FORMAT = "YYYY-MM-DD"

const today = new Date()

class DownloadIntervalData extends Component {
  state = {
    showDialog: false,
    loading: false,
    selectedRange: {
      from: null,
      to: null,
    },
  }

  sendRequest() {
    const deviceId = activeDeviceStore.deviceId
    const { from, to } = this.state.selectedRange
    const showError = () => swal(this.t("common.OOPS"), this.t("intervalData.YOU_CANNOT_REQUIREST_AT_THE_MOMENT"), "info")
    this.setState({ loading: true })

    return api.Report.requestIntervalData(deviceId, from.format(SERVER_FORMAT), to.format(SERVER_FORMAT))
      .then(({ data }) => {
        if (data.success) {
          swal(this.t("common.SUCCESS"), this.t("intervalData.YOUR_REPORT_WILL_BE_EMAILED"), "success")
          this.closeDialog()
        } else {
          showError()
        }
      })
      .catch(() => showError())
      .finally(() => this.setState({ loading: false }))
  }

  openDialog() {
    this.setState({ showDialog: true })
  }

  closeDialog() {
    this.setState({ showDialog: false })
    this.setState({
      selectedRange: {
        from: null,
        to: null,
      },
    })
  }

  render() {
    const { t } = this.props
    const {
      showDialog,
      selectedRange: { from, to },
      loading,
    } = this.state
    this.t = t

    const applyRange = (event, picker) => {
      const { startDate, endDate } = picker
      const diff = endDate.diff(startDate, "days")
      if (diff > 7) {
        swal(t("common.NOTE"), t("intervalData.MAX_RANGE_IS_NUM_DAYS", { num: 7 }), "info")
      } else {
        this.setState({
          selectedRange: {
            from: startDate,
            to: endDate,
          },
        })
      }
    }

    const download = () => {
      this.sendRequest()
    }

    const settings = {
      startDate: today,
      endDate: today,
      ranges: {
        [t("common.TODAY")]: [moment(), moment()],
        [t("common.YESTERDAY")]: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        [t("common.THIS_WEEK")]: [moment().startOf("week"), moment().endOf("week")],
        [t("common.LAST_WEEK")]: [
          moment()
            .subtract(1, "week")
            .startOf("week"),
          moment()
            .subtract(1, "week")
            .endOf("week"),
        ],
      },
      maxDate: new Date(),
      locale: {
        format: "MM-DD-YYYY",
        firstDay: 1,
        applyLabel: t("common.APPLY"),
        cancelLabel: t("common.CANCEL"),
        fromLabel: t("common.FROM"),
        daysOfWeek: moment.weekdaysMin(),
        monthNames: moment.months(),
        customRangeLabel: t("common.CUSTOM_DATE_RANGE"),
      },
    }

    return (
      <>
        <DropdownItem onClick={() => this.openDialog()}>
          <Icon name="download" fw /> <span className="nav-text">{t("menu.DOWNLOAD_INTERVAL_DATA")}</span>
        </DropdownItem>

        <Modal className="interval-range-modal" isOpen={showDialog} toggle={() => this.closeDialog()}>
          <ModalHeader toggle={() => this.closeDialog()}>{t("common.SELECT_DATE_RANGE")}</ModalHeader>
          <ModalBody>
            <DateRangePicker locale="pl" initialSettings={settings} onApply={applyRange}>
              <button className="form-control interval-range-modal__input">
                {from && to ? `${from.format(DATE_FORMAT)} — ${to.format(DATE_FORMAT)}` : t("common.DATE_RANGE")}
              </button>
            </DateRangePicker>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" disabled={!from || !to || loading} onClick={() => download()}>
              {t("common.DOWNLOAD")}
            </Button>{" "}
            <Button onClick={() => this.closeDialog()}>{t("common.CANCEL")}</Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }
}

export default withTranslation()(DownloadIntervalData)
