import React from "react"
import { DropdownMenu, DropdownToggle, UncontrolledDropdown, DropdownItem } from "reactstrap"
import { observer } from "mobx-react"
import classnames from "classnames"
import localeStore from "../../../stores/LocaleStore"

@observer
class LocaleChange extends React.Component {
  languageData = {
    de: "German",
    en: "English",
    it: "Italian",
    pl: "Polish",
    pt: "Portuguese",
    es: "Spanish",
  }

  render() {
    const { current, supported } = localeStore

    if (supported.length <= 1) {
      return null
    }

    const { nav = false, flag = true } = this.props

    return (
      <UncontrolledDropdown nav={nav} inNavbar={nav} className={classnames("locale-select", { "locale-select--styled": !nav })}>
        <DropdownToggle nav={nav} className="nav-link-dropdown locale-select__dropdown-toggle">
          {flag && current in this.languageData && <span className={classnames(`locale-select__flag locale-select__flag--${current}`, { "icon mt-1": nav })} />}
          {current in this.languageData ? this.languageData[current] : current}
        </DropdownToggle>
        <DropdownMenu right={nav} className={"locale-select__dropdown-menu"}>
          {supported.map((locale) => {
            return (
              <DropdownItem
                className="device-select-item locale-select__dropdown-menu-item"
                key={locale}
                active={locale === current}
                onClick={() => localeStore.setCurrent(locale)}
              >
                {flag && locale in this.languageData && <span className={classnames(`locale-select__flag locale-select__flag--${locale}`, { icon: nav })} />}
                {locale in this.languageData ? this.languageData[locale] : locale}
              </DropdownItem>
            )
          })}
        </DropdownMenu>
      </UncontrolledDropdown>
    )
  }
}

export default LocaleChange
