import React from "react"
import { withRouter } from "react-router"
import { Link, NavLink } from "react-router-dom"
import { LinkContainer } from "react-router-bootstrap"
import { observer } from "mobx-react"
import { DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, Nav, NavItem, Button } from "reactstrap"
import Icon from "../../Icons"
import DownloadIntervalData from "../../DownloadIntervalData"
import activeDeviceStore from "../../../stores/ActiveDeviceStore"
import deviceListStore from "../../../stores/DeviceListStore"
import profileStore from "../../../stores/ProfileStore"
import LocaleChange from "./LocaleChange"
import UserNotifications from "./UserNotifications"
import { withTranslation } from "react-i18next"

@observer
class Menu extends React.Component {
  changeDeviceHandler = async (id) => {
    if (activeDeviceStore.id === id) {
      return
    }

    await activeDeviceStore.select(id)
    this.props.history.push("/device/dashboard")
  }

  render() {
    const { username } = profileStore.user
    const { info: activeDevice, isStSeries } = activeDeviceStore
    const deviceList = deviceListStore.list
    const { t } = this.props

    let _activeDeviceLabel = null
    if (activeDevice) _activeDeviceLabel = activeDevice.label
    if (!(_activeDeviceLabel + "").trim().length) {
      _activeDeviceLabel = activeDevice.serialNumber
    }

    return (
      <>
        <Nav navbar className="mr-auto">
          {activeDevice && (
            <>
              <NavItem>
                <NavLink to={"/device/dashboard"} className="nav-link">
                  <Icon name="th" fw /> <span className="nav-text">{t("menu.DASHBOARD")}</span>
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav className="nav-link-dropdown">
                  <Icon name="cog" fw /> <span className="nav-text">{t("menu.SETTINGS")}</span>
                </DropdownToggle>
                <DropdownMenu>
                  <LinkContainer to={"/device/settings/notifications"}>
                    <DropdownItem>
                      <Icon name="bell" fw /> <span className="nav-text">{t("menu.NOTIFICATIONS")}</span>
                    </DropdownItem>
                  </LinkContainer>
                  <LinkContainer to={"/device/settings/energy-rate"}>
                    <DropdownItem>
                      <Icon name="dollar-square" fw /> <span className="nav-text">{t("menu.ENERGY_RATE")}</span>
                    </DropdownItem>
                  </LinkContainer>
                  <LinkContainer to={"/device/settings/application-mode"}>
                    <DropdownItem>
                      <Icon name="sliders-h" fw /> <span className="nav-text">{t("menu.APPLICATION_MODE")}</span>
                    </DropdownItem>
                  </LinkContainer>

                  {!isStSeries && (
                    <LinkContainer to={"/device/settings/reserve-limit"}>
                      <DropdownItem>
                        <Icon name="battery-half" fw /> <span className="nav-text">{t("menu.RESERVE_LIMIT")}</span>
                      </DropdownItem>
                    </LinkContainer>
                  )}

                  <LinkContainer to={"/device/settings/reboot"}>
                    <DropdownItem>
                      <Icon name="reboot" fw /> <span className="nav-text">{t("menu.REBOOT")}</span>
                    </DropdownItem>
                  </LinkContainer>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav className="nav-link-dropdown">
                  <Icon name="chart-bar" fw /> <span className="nav-text">{t("menu.REPORTS")}</span>
                </DropdownToggle>
                <DropdownMenu>
                  <LinkContainer to={"/device/report/power-diagram"}>
                    <DropdownItem>
                      <Icon name="diagram" fw /> <span className="nav-text">{t("menu.POWER_DIAGRAM")}</span>
                    </DropdownItem>
                  </LinkContainer>
                  <LinkContainer to={"/device/report/energy"}>
                    <DropdownItem>
                      <Icon name="chart-bar" fw /> <span className="nav-text">{t("menu.ENERGY_REPORT")}</span>
                    </DropdownItem>
                  </LinkContainer>
                  <LinkContainer to={"/device/report/heatmap"}>
                    <DropdownItem>
                      <Icon name="heatmap" fw /> <span className="nav-text">{t("menu.ENERGY_HEATMAP")}</span>
                    </DropdownItem>
                  </LinkContainer>
                  <LinkContainer to={"/device/report/monthly"}>
                    <DropdownItem>
                      <Icon name="calendar" fw /> <span className="nav-text">{t("menu.MONTHLY_DASHBOARD")}</span>
                    </DropdownItem>
                  </LinkContainer>
                  <LinkContainer to={"/device/report/lifetime"}>
                    <DropdownItem>
                      <Icon name="infinity" fw /> <span className="nav-text">{t("menu.LIFETIME_REPORT")}</span>
                    </DropdownItem>
                  </LinkContainer>
                  <DownloadIntervalData />
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          )}
          {/*
          <NavItem>
            <NavLink to={"/faq"} className="nav-link">
              <Icon name="exclamation-square" fw /> <span className="nav-text">{t("menu.FAQ")}</span>
            </NavLink>
          </NavItem> */}
        </Nav>
        <Nav navbar className="menu-user">
          {deviceList.length > 1 && activeDevice && (
            <UncontrolledDropdown nav inNavbar className="device-select">
              <DropdownToggle nav className="nav-link-dropdown">
                <Icon name="battery-device" fw />
                &nbsp;<span className="device-serial">{_activeDeviceLabel}</span>
                <span className="placeholder">{t("menu.SELECT_HOMEPOWER")}</span>
              </DropdownToggle>
              <DropdownMenu>
                {deviceList.map((device, key) => {
                  let _label = device.label
                  if (!(_label + "").trim().length) {
                    _label = device.serialNumber
                  }
                  return (
                    <DropdownItem
                      className="device-select-item d-flex justify-content-between align-items-center"
                      key={device.id}
                      active={activeDevice.id !== null && activeDevice.id === device.id}
                      onClick={() => this.changeDeviceHandler(device.id)}
                    >
                      {_label}
                      <Link
                        to={"/device/rename/" + device.id}
                        className="btn btn-outline-secondary btn-sm float-right border-0 p-0"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Icon name="pencil" fw />
                      </Link>
                    </DropdownItem>
                  )
                })}
                <LinkContainer to={"/device/link"} className="link-battery">
                  <DropdownItem>
                    <Icon name="plus" fw /> <span className="nav-text">{t("menu.LINK_HOMEPOWER")}</span>
                  </DropdownItem>
                </LinkContainer>
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
          <LocaleChange nav={true} />
          <UserNotifications />
          <UncontrolledDropdown nav inNavbar className="profile">
            <DropdownToggle nav className="profile">
              <Icon name="user-alt" fw className="profile-open" />
              <Icon name="times" fw className="profile-close" />
              <span className="nav-text">{window.localStorage.getItem("currentUser")}</span>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem header>
                <Icon name="user-alt" />
                {username}
                <Link to={"/logout"} className="logout">
                  <Icon name="sign-in" />
                </Link>
              </DropdownItem>
              <LinkContainer to={"/profile"}>
                <DropdownItem>
                  <Icon name="user-circle" fw /> <span className="nav-text">{t("menu.PROFILE")}</span>
                </DropdownItem>
              </LinkContainer>
              {/*<LinkContainer to={ "/settings" }>*/}
              {/*  <DropdownItem>*/}
              {/*    <Icon name="cog" fw /> <span className="nav-text">Settings</span>*/}
              {/*  </DropdownItem>*/}
              {/*</LinkContainer>*/}
              <LinkContainer to={"/device/link"} className="link-battery">
                <DropdownItem>
                  <Icon name="plus" fw /> <span className="nav-text">{t("menu.LINK_NEW_HOMEPOWER")}</span>
                </DropdownItem>
              </LinkContainer>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </>
    )
  }
}

export default withTranslation()(withRouter(Menu))
