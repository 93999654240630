import { action } from 'mobx';

class CountryStore {
  data = {
    au: "Australia",
    nz: "New Zealand",
    pl: "Poland",
    de: "Germany",
    fr: "France",
    gb: "UK",
    it: "Italy",
    pt: "Portugal",
    es: "Spain",
    gr: "Greece",
  };

  get(code) {
    if (!code) {
      return '';
    }

    return this.data[code.toLowerCase()] || '';
  }

  list() {
    return this.data;
  }
}

const countryStore = new CountryStore();

export default countryStore;