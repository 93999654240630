import axios from "axios"
import createAuthRefreshInterceptor from "axios-auth-refresh"
import localeStore from "../stores/LocaleStore"

const http = axios.create({
  baseURL: process.env.API_URL,
})
http.interceptors.request.use((request) => {
  request.headers["Authorization"] = "Bearer " + window.localStorage.getItem("access-token")
  return request
})

http.defaults.headers.common["Application"] = process.env.APPLICATION_ID
http.defaults.headers.common["Instance-Id"] = "web"
http.defaults.headers.post["Content-Type"] = "application/json"
http.defaults.headers.common["Accept-Language"] = "en-EN"

if (localeStore) {
  let { current: currentLocale } = localeStore
  currentLocale = currentLocale ? currentLocale.trim() : null
  if (currentLocale) http.defaults.headers.common["Accept-Language"] = currentLocale
}

const refreshAccessToken = async () => {
  let count = 0
  while (count < 5) {
    try {
      return await fetch(process.env.API_URL + "v1/user/update-access-token", {
        method: "POST",
        body: JSON.stringify({ refresh_token: window.localStorage.getItem("refresh-token") }),
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Application: process.env.APPLICATION_ID,
          "Instance-Id": "web",
        },
      }).then((response) => {
        if (response.status !== 200) {
          throw new Error("")
        }
        return response.json()
      })
    } catch (error) {
      count++
    }
  }
  window.location.href = "/logout"
}

const refreshAuthLogic = (failedRequest) => {
  return refreshAccessToken().then((data) => {
    localStorage.setItem("access-token", data.access_token)
    localStorage.setItem("refresh-token", data.refresh_token)
    failedRequest.response.config.headers["Authorization"] = "Bearer " + data.access_token
  })
}

createAuthRefreshInterceptor(http, refreshAuthLogic)

export default http
