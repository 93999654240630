import React, { Suspense, lazy } from "react"
import { withRouter, Switch, Route, Redirect } from "react-router-dom"
import { inject } from "mobx-react"
import Layout from "./components/App/Layout"
import Loader from "./components/Loader"
import ErrorBoundary from "./components/ErrorBoundary/inde"
import User from "./app/User"
import withActivePlant from "./withActivePlant"
import withPageTitle from "./withPageTitle"
import { useTranslation } from "react-i18next"

const waitFor = (Tag) => (props) => <Tag {...props} />
const waitModal = (Tag, options) => (props) => (
  <Layout.Modal {...options}>
    <Tag {...props} />
  </Layout.Modal>
)

const Home = lazy(() => import("./app/Home/Home"))
const Start = lazy(() => import("./app/Home/Start"))
const DeviceDashboard = lazy(() => import("./app/Device/Dashboard"))
const DeviceReportMonthly = lazy(() => import("./app/Device/Report/Monthly"))
const DeviceReportLifetime = lazy(() => import("./app/Device/Report/Lifetime"))
const DeviceReportPowerDiagram = lazy(() => import("./app/Device/Report/PowerDiagram"))
const DeviceReportEnergy = lazy(() => import("./app/Device/Report/Energy"))
const DeviceReportHeatmap = lazy(() => import("./app/Device/Report/Heatmap"))
const DeviceLink = lazy(() => import("./app/Device/Link/Add"))
const DeviceRename = lazy(() => import("./app/Device/Rename"))
const DeviceRemind = lazy(() => import("./app/Device/Link/Remind"))
const DeviceSettingsNotifications = lazy(() => import("./app/Device/Settings/Notifications"))
const DeviceSettingsEnergyRate = lazy(() => import("./app/Device/Settings/EnergyRate"))
const DeviceSettingsApplicationMode = lazy(() => import("./app/Device/Settings/ApplicationMode"))
const DeviceSettingsReserveLimit = lazy(() => import("./app/Device/Settings/ReserveLimit"))
const DeviceSettingsReboot = lazy(() => import("./app/Device/Settings/Reboot"))
const ProfileView = lazy(() => import("./app/Profile/View"))
const ProfileEdit = lazy(() => import("./app/Profile/Edit"))
const ProfileChangePassword = lazy(() => import("./app/Profile/ChangePassword"))
const ProfileDeleteAccount = lazy(() => import("./app/Profile/DeleteAccount"))
const FaqList = lazy(() => import("./app/Faq/List"))
const DevUiKit = lazy(() => import("./app/Dev/UiKit"))
const Error404 = lazy(() => import("./components/Error404"))

const userPages = ["/signin", "/signup", "/recovery"]

const Routes = ({ location, authStore, history }) => {
  const { currentUser } = authStore
  const [t] = useTranslation()

  if (/signin\/[a-zA-Z0-9]{40}/.test(location.pathname)) {
    return (
      <ErrorBoundary>
        <Suspense fallback={Loader}>
          <Layout.User>
            <Switch location={location}>
              <Route exact strict path="/signin/:token([a-zA-Z0-9]{40})" component={waitFor(User.SigninWithToken)} />
            </Switch>
          </Layout.User>
        </Suspense>
      </ErrorBoundary>
    )
  }

  if (userPages.indexOf(location.pathname) > -1) {
    if (currentUser) {
      return <Redirect to="/" />
    }

    return (
      <ErrorBoundary>
        <Suspense fallback={Loader}>
          <Layout.User>
            <Switch location={location}>
              <Route path="/signin" component={waitFor(User.Signin)} />
              <Route path="/signup" component={waitFor(User.Signin)} />
              <Route path="/recovery" component={waitFor(User.Signin)} />
            </Switch>
          </Layout.User>
          <Switch location={location}>
            <Route exact path="/signup" component={waitModal(User.Signup, { title: t("userProfile.SIGN_UP"), back: "/signin" })} />
            <Route
              exact
              path="/recovery"
              component={waitModal(User.Recovery, {
                title: t("userProfile.FORGOT_PASSWORD") + "?",
                back: "/signin",
                center: true,
              })}
            />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    )
  }

  if (!currentUser) {
    return <Redirect to={{ pathname: "/signin", state: { from: location } }} />
  }

  if ("/logout" === location.pathname) {
    authStore.logout().then(() => (window.location.href = "/signin"))
    return null
  }

  if ("/start" === location.pathname || "/start/link" === location.pathname || "/start/link/remind-verification-code" === location.pathname) {
    return (
      <ErrorBoundary>
        <Suspense fallback={Loader}>
          <Layout.Start>
            <Route component={waitFor(Start)} />
            <Switch location={location}>
              <Route
                exact
                strict
                path="/start/link"
                component={waitModal(DeviceLink, {
                  title: t("device.linkNew.LINK_NEW_HOMEPOWER"),
                  back: "/",
                  size: "xl",
                  center: true,
                  modalClassname: "device-link-page__modal",
                })}
              />
              <Route
                exact
                strict
                path="/start/link/remind-verification-code"
                component={waitModal(DeviceRemind, {
                  title: t("device.remind.DONT_KNOW_VERIFICATION_CODE"),
                  back: "/device/link",
                  center: true,
                })}
              />
            </Switch>
          </Layout.Start>
        </Suspense>
      </ErrorBoundary>
    )
  }

  return (
    <ErrorBoundary>
      <div className="app-main">
        <Layout.Main location={location}>
          <div>
            <Suspense fallback={Loader}>
              <Switch location={location}>
                <Route
                  exact
                  strict
                  path={["/device/dashboard/:date(\\d{4}-\\d{2}-\\d{2})*", "/device/link", "/device/link/remind-verification-code"]}
                  component={waitFor(withActivePlant(withPageTitle(DeviceDashboard, t("menu.DASHBOARD") + " :id")))}
                />
                <Route
                  exact
                  strict
                  path="/device/report/monthly/:date(\d{4}-\d{2})*"
                  component={waitFor(withActivePlant(withPageTitle(DeviceReportMonthly, t("menu.MONTHLY_DASHBOARD") + " :id")))}
                />
                <Route
                  exact
                  strict
                  path="/device/report/lifetime"
                  component={waitFor(withActivePlant(withPageTitle(DeviceReportLifetime, t("menu.LIFETIME_REPORT") + " :id")))}
                />
                <Route
                  exact
                  strict
                  path="/device/report/power-diagram/:date(\d{4}-\d{2}-\d{2})*"
                  component={waitFor(withActivePlant(withPageTitle(DeviceReportPowerDiagram, t("menu.POWER_DIAGRAM") + " :id")))}
                />
                <Route
                  exact
                  strict
                  path="/device/report/energy/:date(\d{4}-\d{2})*"
                  component={waitFor(withActivePlant(withPageTitle(DeviceReportEnergy, t("menu.ENERGY_REPORT") + " :id")))}
                />
                <Route
                  exact
                  strict
                  path="/device/report/heatmap/:reportType(generation|energy-consumption)*/:dateType(hourly|daily)*/:date(\d{4}-\d{2}|\d{4})*"
                  component={waitFor(withActivePlant(withPageTitle(DeviceReportHeatmap, t("menu.ENERGY_HEATMAP") + " :id")))}
                />
                <Route
                  exact
                  strict
                  path="/device/settings/notifications"
                  component={waitFor(withActivePlant(withPageTitle(DeviceSettingsNotifications, t("menu.SETTINGS") + " :id - " + t("menu.NOTIFICATIONS"))))}
                />
                <Route
                  exact
                  strict
                  path="/device/settings/energy-rate"
                  component={waitFor(withActivePlant(withPageTitle(DeviceSettingsEnergyRate, t("menu.SETTINGS") + " :id - " + t("menu.ENERGY_RATE"))))}
                />
                <Route
                  exact
                  strict
                  path="/device/settings/application-mode"
                  component={waitFor(
                    withActivePlant(withPageTitle(DeviceSettingsApplicationMode, t("menu.SETTINGS") + " :id - " + t("menu.APPLICATION_MODE")))
                  )}
                />
                <Route
                  exact
                  strict
                  path="/device/settings/reserve-limit"
                  component={waitFor(withActivePlant(withPageTitle(DeviceSettingsReserveLimit, t("menu.SETTINGS") + " :id - " + t("menu.RESERVE_LIMIT"))))}
                />
                <Route
                  exact
                  strict
                  path="/device/settings/reboot"
                  component={waitFor(withActivePlant(withPageTitle(DeviceSettingsReboot, t("menu.SETTINGS") + " :id - " + t("menu.REBOOT"))))}
                />
                <Route exact strict path="/profile/edit" component={waitFor(withPageTitle(ProfileEdit, t("userProfile.view.EDIT_PROFILE")))} />
                <Route
                  exact
                  strict
                  path={["/profile", "/profile/change-password"]}
                  component={waitFor(withPageTitle(ProfileView, t("userProfile.view.CHANGE_PASSWORD")))}
                />
                <Route exact strict path="/faq" component={waitFor(withPageTitle(FaqList, t("menu.FAQ")))} />
                {process.env.APP_ENV === "dev" && <Route exact strict path="/dev/ui-kit" component={waitFor(withPageTitle(DevUiKit, "UI Kit"))} />}
                <Route exact strict path="/" component={waitFor(Home)} />
                <Route component={waitFor(Error404)} />
              </Switch>
              <Switch location={location}>
                <Route
                  exact
                  strict
                  path="/device/link"
                  component={waitModal(DeviceLink, {
                    title: t("device.linkNew.LINK_NEW_HOMEPOWER"),
                    back: "/",
                    size: "xl",
                    center: true,
                    modalClassname: "device-link-page__modal",
                  })}
                />
                <Route
                  exact
                  strict
                  path="/device/link/remind-verification-code"
                  component={waitModal(DeviceRemind, {
                    title: t("device.remind.DONT_KNOW_VERIFICATION_CODE"),
                    back: "/device/link",
                    center: true,
                  })}
                />
                <Route
                  exact
                  strict
                  path="/device/rename/:id(\d+)"
                  component={waitModal(DeviceRename, {
                    title: t("device.CHANGE_DEVICE_LABEL"),
                    back: "/",
                    size: "xl",
                    center: true,
                    modalClassname: "device-link-page__modal",
                  })}
                />
                <Route
                  exact
                  strict
                  path="/profile/change-password"
                  component={waitModal(ProfileChangePassword, {
                    title: t("userProfile.view.CHANGE_PASSWORD"),
                    back: "/profile",
                    center: false,
                  })}
                />
                <Route
                  exact
                  strict
                  path="/profile/delete-account"
                  component={waitModal(ProfileDeleteAccount, {
                    title: t("userProfile.view.DELETE_ACCOUNT"),
                    back: "/profile",
                    center: false,
                  })}
                />
              </Switch>
            </Suspense>
          </div>
        </Layout.Main>
      </div>
    </ErrorBoundary>
  )
}

export default inject("authStore")(withRouter(Routes))
