import React from "react"
import { inject, observer } from "mobx-react"
import { withTranslation } from "react-i18next"
import api from "api"

@inject("authStore")
@observer
class SigninWithToken extends React.Component {
  state = {
    token: null,
  }

  constructor(props) {
    super(props)

    let { token } = props.match.params

    this.state = {
      token,
    }
  }

  componentWillMount() {
    this.props.authStore.logout().then(() => {
      api.Auth.loginWithToken(this.state.token)
        .then(({ data }) => this.props.authStore.setCurrentUser(data))
        .finally(() => {
          window.location.href = "/"
        })
    })
  }

  render() {
    const { t } = this.props
    return (
      <div className="app-login-page__wrapper">
        <div className="app-login-page__loader">
          <div className="spinner-border" role="status">
            <span className="sr-only">{t("common.LOADING")}...</span>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(SigninWithToken)
