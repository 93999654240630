// import 'core-js/es6/string';
// import 'core-js/es6/array';
// import 'core-js/es6/map';
// import 'core-js/es6/set';
// import 'core-js/es6/object';
// import 'core-js/es6/promise';
// import 'core-js/es7/object';
// import 'core-js/es7/array';
// import 'raf/polyfill';

import React, { Suspense } from "react"
import ReactDOM from "react-dom"
import promiseFinally from "promise.prototype.finally"
import { Provider } from "mobx-react"
import App from "./App"
import stores from "./stores/index"
import { registerLocale } from "react-datepicker"
import enAU from "date-fns/locale/en-AU"
registerLocale("enAU", enAU)
import moment from "moment/min/moment-with-locales"
import "moment/locale/en-au"
moment().locale("en-au")
import "./i18n"
require("icons.font.js")
import Loader from "./components/Loader"

window._____APP_STATE_____ = stores

promiseFinally.shim()

//configure({ enforceActions: "observed" });

// const browserHistory = createBrowserHistory();
// const routingStore = new RouterStore();
// const history = syncHistoryWithStore(browserHistory, routingStore);
//testbuild111
ReactDOM.render(
  <Suspense fallback={<Loader />}>
    <Provider {...stores}>
      <App />
    </Provider>
  </Suspense>,
  document.getElementById("app-site")
)
