import React from "react"
import { useTranslation } from "react-i18next"
import Cookies from "universal-cookie"

const COOKIE_NAME = "cookies-clause-result"

const CookiesClause = () => {
  const [t] = useTranslation()
  const cookies = new Cookies()
  const currentCookie = cookies.get(COOKIE_NAME)

  const [showNeeded, setShowNeeded] = React.useState(typeof currentCookie === "undefined")

  const setCookie = (result) => {
    cookies.set(COOKIE_NAME, result, { path: "/" })
    setShowNeeded(false)
  }
  const allowCookies = (e) => {
    e.preventDefault()
    setCookie(1)
  }
  const declineCookies = (e) => {
    e.preventDefault()
    setCookie(0)
  }

  if (!showNeeded) return null

  return (
    <aside className="cookies-clause">
      <div className="container">
        <div className="cookies-clause__content">
          <div className="cookies-clause__notice">
            <p>
              <span
                dangerouslySetInnerHTML={{
                  __html: t("cookies.COOKIES_CLAUSE", {
                    interpolation: { escapeValue: false },
                  }),
                }}
              />{" "}
              <a style={{ color: "#44AECA" }} href="https://hyperian.com/privacy">
                {t("cookies.COOKIE_POLICY")}
              </a>
              .
            </p>
          </div>

          <div className="cookies-clause__buttons">
            <button
              className="btn btn-primary rounded-pill cookies-clause__button cookies-clause__button_primary"
              aria-label="Allow Cookies"
              onClick={allowCookies}
            >
              {t("cookies.ALLOW_COOKIES")}
            </button>
            <button
              className="btn cookies-clause__button cookies-clause__button_reject"
              aria-label="Decline"
              onClick={declineCookies}
            >
              {t("cookies.DECLINE")}
            </button>
          </div>
        </div>
      </div>
    </aside>
  )
}

export default CookiesClause
