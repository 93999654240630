import firebase from "firebase"

// Initialize Firebase
const config = {
  apiKey: process.env.FIREBASE_APIKEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT_ID,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
}

firebase.initializeApp(config)
const auth = firebase.auth()

const googleAuthProvider = new firebase.auth.GoogleAuthProvider()
googleAuthProvider.addScope("email")
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider()
facebookAuthProvider.setCustomParameters({
  display: "popup",
})
const appleAuthProvider = new firebase.auth.OAuthProvider("apple.com")
appleAuthProvider.addScope("email")
appleAuthProvider.addScope("name")

export { auth, appleAuthProvider, googleAuthProvider, facebookAuthProvider }
