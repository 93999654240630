import React from "react"
import { Link } from "react-router-dom"
import { inject, observer } from "mobx-react"
import { Button, Input } from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faApple, faFacebookF, faGoogle } from "@fortawesome/free-brands-svg-icons"
import FormValidator from "../../components/Forms/Validator.js"

@inject("authStore")
@observer
class Signin extends React.Component {
  state = {
    formLogin: {},
  }

  componentWillUnmount() {
    this.props.authStore.reset()
  }

  handleUsernameChange = (e) => {
    this.validateOnChange(e)
    this.props.authStore.setUsername(e.target.value)
  }

  handlePasswordChange = (e) => {
    this.validateOnChange(e)
    this.props.authStore.setPassword(e.target.value)
  }

  validateOnChange = (event) => {
    const input = event.target
    const form = input.form
    const result = FormValidator.validate(input)

    this.setState({
      errors: {
        ...this.state.errors,
        [input.name]: result,
      },
    })
  }

  onSubmit = (e) => {
    const form = e.target
    const inputs = [...form.elements].filter((i) => ["INPUT", "SELECT"].includes(i.nodeName))

    const { errors, hasError } = FormValidator.bulkValidate(inputs)

    this.setState({
      errors,
    })

    e.preventDefault()

    if (!hasError) {
      this.props.authStore
        .signIn()
        .then(() => this.props.history.push("/"))
        .catch((err) => {
          const message = err.response && err.response.data && err.response.data.message
          if (message) {
            this.setState({
              errors: {
                username: [message],
              },
            })
          }
        })
    }
  }

  hasError = (inputName) => {
    return this.state.errors && this.state.errors[inputName] && this.state.errors[inputName].length > 0
  }

  getErrors = (inputName) => {
    if (!this.hasError(inputName)) {
      return ""
    }
    return this.state.errors[inputName].join(", ")
  }

  render() {
    const { t } = this.props
    const { values, inProgress } = this.props.authStore
    FormValidator.setTranslation(t)

    return (
      <div className="app-login-page__wrapper">
        {inProgress && (
          <div className="app-login-page__loader">
            <div className="spinner-border" role="status">
              <span className="sr-only">{t("common.LOADING")}...</span>
            </div>
          </div>
        )}
        <h1 className="app-login-title">{t("userProfile.LOG_IN")}</h1>

        <form action="" name="formLogin" onSubmit={this.onSubmit} className="app-login-form">
          <div className="form-group">
            <Input
              type="text"
              name="username"
              placeholder={t("userProfile.USERNAME")}
              invalid={this.hasError("username")}
              onChange={this.handleUsernameChange}
              data-validate='["required"]'
              value={values.username}
              autoComplete="off"
            />
            {this.hasError("username") && <span className="invalid-feedback"> {this.getErrors("username")}</span>}
          </div>

          <div className="form-group">
            <Input
              type="password"
              name="password"
              placeholder={t("userProfile.PASSWORD")}
              invalid={this.hasError("password")}
              onChange={this.handlePasswordChange}
              data-validate='["required"]'
              value={values.password}
              autoComplete="off"
            />
            {this.hasError("password") && <span className="invalid-feedback"> {this.getErrors("password")}</span>}
          </div>

          <div className="mb-3">
            <Link to="/recovery" className="app-recovery-link">
              {t("userProfile.FORGOT_PASSWORD")}?
            </Link>
          </div>

          <Button color="primary" className="text-uppercase btn-block px-3" type="submit" disabled={inProgress}>
            {t("userProfile.LOG_IN")}
          </Button>
        </form>

        <div className="app-social-block">
          <div className="app-social-block-title">{t("userProfile.LOGIN_VIA_SOCIAL")}</div>
          <ul className="app-social-links">
            <li
              className="app-social-link"
              onClick={() => {
                this.props.authStore.appleSignIn().then((data) => this.props.history.push(data.user ? "/" : "/signup"))
              }}
            >
              <FontAwesomeIcon icon={faApple} />
            </li>

            <li
              className="app-social-link"
              onClick={() => {
                this.props.authStore.facebookSignIn().then((data) => this.props.history.push(data.user ? "/" : "/signup"))
              }}
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </li>

            <li
              className="app-social-link"
              onClick={() => {
                this.props.authStore.googleSignIn().then((data) => this.props.history.push(data.user ? "/" : "/signup"))
              }}
            >
              <FontAwesomeIcon icon={faGoogle} />
            </li>
          </ul>
        </div>

        <Link to="/signup" className="app-signup-link btn btn-link px-3">
          <div className="app-signup-link-icon" />
          {t("userProfile.CREATE_ACCOUNT")}
        </Link>
      </div>
    )
  }
}

Signin.contextTypes = {
  router: PropTypes.object,
}

export default withTranslation()(Signin)
