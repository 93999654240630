import { observable, action, reaction } from "mobx"
import api from "api"
import i18n from "../i18n"
import Cookies from "universal-cookie"

const COOKIE_NAME = "i18nextLng"
const cookies = new Cookies()
const cookieLang = cookies.get(COOKIE_NAME)

class LocaleStore {
  @observable current = window.localStorage.getItem("i18nextLng")
  @observable supported = JSON.parse(window.localStorage.getItem("i18nextSupportedLng")) || []

  @action setCurrent = (value) => {
    cookies.set(COOKIE_NAME, value, { path: "/" })
    this.current = value
  }

  constructor() {
    i18n.on("initialized", async (options) => {
      const getLanguage = () => i18n.language
      if (getLanguage() === undefined) {
        this.detect()
      }
    })

    reaction(
      () => this.current,
      (lng) => {
        if (lng) {
          i18n.changeLanguage(lng).then((r) => r)
        } else {
          window.localStorage.removeItem("i18nextLng")
          i18n.changeLanguage(null).then((r) => r)
        }
      }
    )
    reaction(
      () => this.supported,
      (list) => {
        if (list) {
          window.localStorage.setItem("i18nextSupportedLng", JSON.stringify(list))
          if (list.indexOf(this.current) === -1) {
            this.current = list[0]
          }
        } else {
          window.localStorage.removeItem("i18nextSupportedLng")
        }
      }
    )
  }

  @action detect() {
    return api.Locale.list().then(({ data }) => {
      this.current = cookieLang || data.list[0]
      this.supported = data.list
    })
  }

  @action fromUser(user) {
    const { languages } = user
    if (!this.current) {
      this.current = cookieLang || data.list[0]
    }
    this.supported = languages
  }

  @action reset() {
    this.current = null
    this.supported = null
  }
}

const localeStore = new LocaleStore()

export default localeStore
