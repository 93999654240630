import http from "./http"

const Auth = {
  current: () => http.get("/v1/user/current"),
  login: (username, password) => http.post("/v1/user/authorise-by-password", { username, password }),
  loginWithToken: (token) => http.post("/v1/user/authorise-by-token", { token }),
  loginSocial: (token) => http.post("/v1/user/authorise-by-social-token", { token }),
  logout: () => http.get("/v1/user/logout"),
  register: (username, password, email, phone, lastname, firstname, country) =>
    http.post("/v1/user/register-with-username-and-password", {
      username,
      password,
      email,
      phone,
      country,
      last_name: lastname,
      first_name: firstname,
    }),
  registerBySocial: (token, email, firstname, lastname) =>
    http.post("/v1/user/register-with-social-token", {
      token,
      email,
      last_name: lastname,
      first_name: firstname,
    }),
  updateToken: (refreshToken) => http.post("/v1/user/update-access-token", { refresh_token: refreshToken }),
  recoverySendCode: (username) => http.post("/v1/user/forgot-password-send-code", { username }),
  recoveryValidateCode: (username, code) => http.post("/v1/user/forgot-password-validate-code", { username, code }),
}

export default Auth
