import React from "react"
import activeDeviceStore from "./stores/ActiveDeviceStore"
import { observer } from "mobx-react"
import { withTranslation } from "react-i18next"

const withPageTitle = (WrappedComponent, title) => {
  let component = class extends React.Component {
    componentDidMount() {
      const { t } = this.props

      if (title !== "") {
        const device = activeDeviceStore.device
        if (null !== device) {
          title = title.replace(/\:id/, device.serialNumber)
        }
        document.title = title + ". " + t("common.PAGE_TITLE")
      }
    }

    componentWillUnmount() {
      const { t } = this.props
      document.title = t("common.PAGE_TITLE")
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  return withTranslation()(component)
}

export default withPageTitle
