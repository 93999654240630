import http from "./http"

const Profile = {
  current: () => {
    return http.get("/v1/user/get-my-profile")
  },
  update: (username, email, phone, firstname, lastname, country) => {
    return http.post("/v1/user/update-profile", {
      username: username,
      email: email,
      phone: phone,
      first_name: firstname,
      last_name: lastname,
      country,
    })
  },
  updatePassword: (newPassword, oldPassword) => {
    return http.post("/v1/user/update-password", { newPassword, oldPassword })
  },
  deleteMyProfile: (notes) => {
    return http.post("/v1/user/delete-my-profile", { notes })
  },
}

export default Profile
