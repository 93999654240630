import React from 'react';
import {Link} from "react-router-dom";

const Short = () => {
  return (
    <div className="logo logo-short">
      <Link className="logo-link" to="/" title="Energizer">
        <div className="hyperian" />
      </Link>
    </div>
  );
}

export default Short;
