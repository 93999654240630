import React from "react"
import { Link } from "react-router-dom"
import { inject, observer } from "mobx-react"
import { Button, Input } from "reactstrap"
import classNames from "classnames"
import FormValidator from "../../components/Forms/Validator.js"
import Icon from "../../components/Icons"
import { withTranslation } from "react-i18next"

@inject("authStore")
@observer
class Recovery extends React.Component {
  recoveryCodeLength = 7
  recoveryCode = {}

  state = {
    step: 1,
    email: "",
    recoveryForm: {
      username: "",
      recoveryCode: "",
    },
    errors: {},
  }

  handleFieldChange = (e) => {
    const input = e.target
    const form = input.form
    const value = input.type === "checkbox" ? input.checked : input.value

    const result = FormValidator.validate(input)

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
      },
      errors: {
        ...this.state.errors,
        [input.name]: result,
      },
    })
  }

  handleRecoveryCodeChange = (e, index) => {
    const input = e.target
    const form = input.form
    const value = input.value
    const result = FormValidator.validate(input)

    this.recoveryCode[index] = value
    if ("" === value && index > 0) {
      form["recoveryCode" + (index - 1)].focus()
    } else if ("" !== value && index + 1 < this.recoveryCodeLength) {
      form["recoveryCode" + (index + 1)].focus()
    }

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
      },
      errors: {
        ...this.errors,
        [input.name]: result,
      },
    })

    this.handleFieldChange(e)
  }

  onSubmit = (e) => {
    const form = e.target
    const inputs = [...form.elements].filter((i) => ["INPUT", "SELECT"].includes(i.nodeName))

    const { errors, hasError } = FormValidator.bulkValidate(inputs)

    this.setState({
      errors,
    })

    e.preventDefault()

    if (!hasError) {
      switch (this.state.step) {
        case 1:
          this.props.authStore
            .recoverySendCode(this.state.recoveryForm.username)
            .then(({ data }) => {
              this.setState({
                step: 2,
                email: data.email,
              })
            })
            .catch((err) => {
              const message = err.response && err.response.data && err.response.data.message
              if (message) {
                this.setState({
                  errors: {
                    username: [message],
                  },
                })
              }
            })
          break
        case 2:
          let recoveryCodeValue = ""
          for (let i = 0; i < this.recoveryCodeLength; i++) {
            let letter = this.recoveryCode[i]
            recoveryCodeValue = recoveryCodeValue.concat(undefined === letter ? " " : letter)
          }
          this.props.authStore
            .recoveryValidateCode(this.state.recoveryForm.username, recoveryCodeValue)
            .then(({ data }) => {
              window.document.getElementById("layout-modal-title").remove()
              this.setState({
                step: 3,
              })
            })
            .catch((err) => {
              const errors = err.response && err.response.data && err.response.data.arguments
              if (errors && Object.keys(errors).length) {
                this.setState({
                  errors,
                })
              }
            })
          break
        case 3:
          this.props.history.push("/signin")
          break
      }
    }
  }

  hasError = (inputName) => {
    return this.state.errors && this.state.errors[inputName] && this.state.errors[inputName].length > 0
  }

  getErrors = (inputName) => {
    if (!this.hasError(inputName)) {
      return ""
    }
    return this.state.errors[inputName].join(", ")
  }

  renderRecoveryCodeInputs = (t) => {
    let inputs = [],
      hasSummaryError = false
    for (let i = 0; i < this.recoveryCodeLength; i++) {
      inputs.push(
        <Input
          type="text"
          name={"recoveryCode" + i}
          placeholder=""
          maxLength="1"
          invalid={this.hasError("recoveryCode" + i, "required") || this.hasError("code")}
          onChange={(event) => this.handleRecoveryCodeChange(event, i)}
          data-validate='["required"]'
          value={this.recoveryCode[i]}
          className="form-control form-control-recovery-code"
        />
      )
      hasSummaryError = hasSummaryError || this.hasError("recoveryCode" + i)
    }

    return (
      <>
        <div
          className={classNames("form-group d-flex justify-content-between text-center", {
            "mb-3": hasSummaryError || this.getErrors("code"),
            "mb-5": !hasSummaryError && !this.getErrors("code"),
          })}
        >
          {inputs}
        </div>
        {hasSummaryError && (
          <span className="d-block invalid-feedback">{t("userProfile.recovery.CODE_IS_REQUIRED")}</span>
        )}
      </>
    )
  }
  render() {
    const { inProgress } = this.props.authStore
    const { t } = this.props

    return (
      <div className="app-recovery">
        <div className="row">
          <div className="col-12 col-md-6 offset-md-3">
            <p className="text-center font-weight-light">
              {this.state.step === 1 && t("userProfile.recovery.ENTER_USERNAME_AND_PRESS_SEND_CODE")}
              {this.state.step === 2 &&
                t("userProfile.recovery.WE_SENT_VERIFICATION_CODE_TO_EMAIL", { email: this.state.email })}
            </p>
            {this.state.step === 2 && (
              <p className="text-center text-uppercase mb-0 font-weight-bold">
                {t("userProfile.recovery.VERIFICATION_CODE")}
              </p>
            )}
            {this.state.step === 3 && (
              <p className="app-recovery-success text-center">{t("userProfile.recovery.PASSWORD_EMAILED_TO_YOU")}</p>
            )}
          </div>
        </div>
        <form className="form-validate" action="" name="recoveryForm" onSubmit={this.onSubmit}>
          <div className="row mt-4">
            <div
              className={classNames("col-12", {
                "col-md-4 offset-md-4": this.state.step !== 2,
                "col-md-8 offset-md-2": this.state.step === 2,
              })}
            >
              {this.state.step === 1 && (
                <div className="form-group">
                  <Input
                    type="text"
                    name="username"
                    placeholder={t("userProfile.USERNAME")}
                    invalid={this.hasError("username")}
                    onChange={this.handleFieldChange}
                    data-validate='["required"]'
                    value={this.state.recoveryForm.username}
                    disabled={this.state.step > 1}
                  />
                  {this.hasError("username") && <span className="invalid-feedback"> {this.getErrors("username")}</span>}
                </div>
              )}

              {this.state.step === 2 && (
                <div className="form-group">
                  {this.renderRecoveryCodeInputs(t)}
                  {this.hasError("code") && <span className="invalid-feedback"> {this.getErrors("code")}</span>}
                </div>
              )}
              {this.state.step === 3 && (
                <div>
                  <div className="mt-3 mb-5 text-center app-recovery-success-smile">
                    <Icon name="emoji-smile" />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row mb-4">
            {this.state.step === 3 && (
              <div className="col-3">
                <Link to="/signin" className="btn btn-link px-3">
                  <Icon name="chevron-left" fw />
                  {t("common.BACK")}
                </Link>
              </div>
            )}
            <div
              className={classNames({
                "col-12 col-md-4 offset-md-4": this.state.step !== 3,
                "col-8 col-md-6": this.state.step === 3,
              })}
            >
              <Button type="submit" color="primary" className="text-uppercase btn-block px-3" disabled={inProgress}>
                {this.state.step === 1 && t("userProfile.recovery.SEND_CODE")}
                {this.state.step === 2 && t("userProfile.recovery.RESET_PASSWORD")}
                {this.state.step === 3 && t("userProfile.recovery.BACK_TO_PREV_SCREEN")}
              </Button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default withTranslation()(Recovery)
